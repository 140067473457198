import { IQuestion } from 'types/Question.types';

export const getIndexesOfInputsInOutputs = (
  inputs: string[],
  outputs: object
): number[] => {
  const indexesOfInputsInOutputs: number[] = [];
  const outputKeys = Object.keys(outputs);
  // Finds the index of each input in the outputs object keys array
  for (const inputValue of inputs) {
    const indexOfInputInOutput = outputKeys.indexOf(inputValue);
    if (indexOfInputInOutput !== -1) {
      indexesOfInputsInOutputs.push(indexOfInputInOutput);
    }
  }
  return indexesOfInputsInOutputs;
};

export const getValuesForMultipleListQuestion = (question: IQuestion) => {
  const indexesOfInputsInOutputs: number[] = getIndexesOfInputsInOutputs(
    question.inputs,
    question.outputs
  );
  const selectedValues: any[] = [];
  for (const option of question.options) {
    for (const indexOfInputInOutputs of indexesOfInputsInOutputs) {
      let outputsArray =
        question.outputs[Object.keys(question.outputs)[indexOfInputInOutputs]];
      if (!Array.isArray(outputsArray)) {
        outputsArray = [outputsArray];
      }
      if (outputsArray.includes(option[indexOfInputInOutputs])) {
        selectedValues.push(option[0]);
      }
    }
  }
  return selectedValues;
};

export const getValuesForSingleListQuestion = (question: IQuestion) => {
  const indexesOfInputsInOutputs: number[] = getIndexesOfInputsInOutputs(
    question.inputs,
    question.outputs
  );
  for (const option of question.options) {
    let matchingOption = true;
    for (const index of indexesOfInputsInOutputs) {
      if (
        option[index] !== question.outputs[Object.keys(question.outputs)[index]]
      ) {
        matchingOption = false;
      }
    }
    if (matchingOption) {
      return option[0];
    }
  }
};

export const getValuesForSingleTableQuestion = (question: IQuestion) => {
  const selectedOptions: any[] = [];
  for (const option of question.options) {
    let i = 0;
    let matchingOption = true;
    for (const outputKey of Object.keys(question.outputs)) {
      if (question.outputs[outputKey] !== option[i]) {
        matchingOption = false;
      }
      i++;
    }
    if (matchingOption) {
      selectedOptions.push(option);
    }
  }
  return selectedOptions;
};

export const getValuesForMultipleTableQuestion = (question: IQuestion) => {
  const selectedOptions: any[] = [];
  for (const option of question.options) {
    let i = 0;
    let matchingOption = true;
    for (const outputKey of Object.keys(question.outputs)) {
      if (
        question.outputs[outputKey] &&
        Array.isArray(question.outputs[outputKey])
      ) {
        if (!question.outputs[outputKey].includes(option[i])) {
          matchingOption = false;
        }
      } else {
        if (question.outputs[outputKey] !== option[i]) {
          matchingOption = false;
        }
      }
      i++;
    }
    if (matchingOption) {
      selectedOptions.push(option);
    }
  }

  return selectedOptions;
};

export const getValuesForRadioQuestion = (question: IQuestion) => {
  const indexesOfInputsInOutputs: number[] = getIndexesOfInputsInOutputs(
    question.inputs,
    question.outputs
  );
  for (const option of question.options) {
    let matchingOption = true;
    for (const index of indexesOfInputsInOutputs) {
      if (
        option[index] !== question.outputs[Object.keys(question.outputs)[index]]
      ) {
        matchingOption = false;
      }
    }
    if (matchingOption) {
      return option[0];
    }
  }
};

export const getValuesForColorQuestion = (question: IQuestion) => {
  const indexesOfInputsInOutputs: number[] = getIndexesOfInputsInOutputs(
    question.inputs,
    question.outputs
  );

  for (const option of question.options) {
    let matchingOption = true;
    for (const index of indexesOfInputsInOutputs) {
      if (
        option[index] !== question.outputs[Object.keys(question.outputs)[index]]
      ) {
        matchingOption = false;
      }
    }

    if (matchingOption) {
      return option[indexesOfInputsInOutputs[0]];
    }
  }
};

export const questionHasValue = (values: any, questionName: string) => {
  return Array.isArray(values[questionName])
    ? !!values[questionName].length
    : hasValue(values[questionName]);
};

const hasValue = (value: any) => {
  if ([undefined, '0', ''].includes(value)) {
    return false;
  } else {
    return true;
  }
};

// Remove values from valuesFromOutputs for outputs which are empty arrays or null values
// Hidden questions are excluded from valuesFromOutputs inside useGetValuesFromQuestionOutputs hook
export const formatValuesFromOutputs = (valuesFromOutputs: object) => {
  const formattedValuesFromOutputs = {};
  for (const key of Object.keys(valuesFromOutputs)) {
    if (Array.isArray(valuesFromOutputs[key])) {
      // If array and is empty array, ignore output
      if (valuesFromOutputs[key].length === 0) {
        continue;
      } else {
        formattedValuesFromOutputs[key] = valuesFromOutputs[key];
        continue;
      }
    } else {
      // If value is null or undefined, ignore output
      if (
        valuesFromOutputs[key] === null ||
        valuesFromOutputs[key] === undefined
      ) {
        continue;
      } else {
        formattedValuesFromOutputs[key] = valuesFromOutputs[key];
        continue;
      }
    }
  }
  return valuesFromOutputs;
};

export const extractQuestionPrice = (
  pricing: object,
  questionInitialKey: string,
  showNetPrices: boolean
): number | undefined => {
  if (!pricing) {
    return undefined;
  }
  let price = 0;
  Object.keys(pricing).forEach((pricingKey) => {
    const pricingItem = pricing[pricingKey];
    if (pricingItem.question === questionInitialKey) {
      if (showNetPrices && pricingItem.type.includes('net')) {
        price += pricing[pricingKey].value;
      } else if (!showNetPrices && pricingItem.type.includes('gross')) {
        price += pricing[pricingKey].value;
      }
    }
  });

  return price || undefined;
};
