export enum ReactQueryKeys {
  GET_MODELS = 'GET_MODELS',
  GET_MODELS_INFINITE = 'GET_MODELS_INFINITE',
  GET_SHOPPING_CART = 'GET_SHOPPING_CART',
  GET_COMPANIES_INFINITE = 'GET_COMPANIES_INFINITE',
  GET_SUPPLIERS_INFINITE = 'GET_SUPPLIERS_INFINITE',
  GET_CATEGORIES = 'GET_CATEGORIES',
  GET_CATEGORIES_INFINITE = 'GET_CATEGORIES_INFINITE',
  GET_SUPPLIER_COMPANIES = 'GET_SUPPLIER_COMPANIES',
  GET_SUPPLIER_COMPANY_MODELS = 'GET_SUPPLIER_COMPANY_MODELS',
  GET_SUPPLIER_USERS = 'GET_SUPPLIER_USERS',
  GET_SUPPLIER_USERS_INFINITE = 'GET_SUPPLIER_USERS_INFINITE',
  GET_COMPANY_USERS = 'GET_COMPANY_USERS',
  GET_COMPANY_USERS_INFINITE = 'GET_COMPANY_USERS_INFINITE',
  GET_ELIGIBLE_COMPANIES_FOR_CREATING_SUPPLIER_COMPANY_MODEL = 'GET_ELIGIBLE_COMPANIES_FOR_CREATING_SUPPLIER_COMPANY_MODEL',
  GET_ELIGIBLE_MODELS_FOR_CREATING_SUPPLIER_COMPANY_MODEL = 'GET_ELIGIBLE_MODELS_FOR_CREATING_SUPPLIER_COMPANY_MODEL',
  GET_SINGLE_SUPPLIER_COMPANY = 'GET_SINGLE_SUPPLIER_COMPANY',
  GET_SINGLE_MODEL = 'GET_SINGLE_MODEL',
  GET_ELIGIBLE_COMPANIES_FOR_CREATING_SUPPLIER_COMPANY = 'GET_ELIGIBLE_COMPANIES_FOR_CREATING_SUPPLIER_COMPANY',
  GET_SUPPLIERS = 'GET_SUPPLIERS',
  GET_MODEL_CONFIGURATIONS = 'GET_MODEL_CONFIGURATIONS',
  GET_COMPANY = 'GET_COMPANY',
  GET_SUPPLIER = 'GET_SUPPLIER',
}
