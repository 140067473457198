import i18n, { FALLBACK_LOCALE } from 'providers/i18n/i18n';
import { useQuery } from 'react-query';
import { getModels } from 'services/ModelService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetModels = (
  page: number,
  perPage: number,
  searchBy: string,
  supplierId: number | null,
  categoryId: number | null,
  recentlyViewed: boolean,
  favorites: boolean,
  isEnabled = true
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_MODELS,
      page,
      perPage,
      searchBy,
      supplierId,
      categoryId,
      recentlyViewed,
      favorites,
      i18n.resolvedLanguage || FALLBACK_LOCALE,
    ],
    queryFn: () => {
      return getModels(
        page,
        perPage,
        searchBy,
        supplierId,
        categoryId,
        recentlyViewed,
        favorites
      );
    },
    enabled: isEnabled,
  });
