import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { favoriteModel, unfavoriteModel } from 'services/FavoritesService';
import { LogyxError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useState, useEffect } from 'react';

export const useFavoriteModel = () => {
  const { t } = useTranslation();
  return useMutation(
    (modelId: number) => {
      return favoriteModel(modelId);
    },
    {
      onSuccess: () => {
        toast.success(t('Model added to favourites'));
      },
      onError: (error: LogyxError) => {
        console.log('error: ', error);
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.FAVORITE_MODEL,
    }
  );
};

export const useUnfavoriteModel = () => {
  const { t } = useTranslation();
  return useMutation(
    (modelId: number) => {
      return unfavoriteModel(modelId);
    },
    {
      onSuccess: () => {
        toast.success(t('Model removed from favourites'));
      },
      onError: (error: LogyxError) => {
        console.log('error: ', error);

        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.UNFAVORITE_MODEL,
    }
  );
};

interface IUseFavoriteToggleProps {
  initialIsFavorite: boolean;
  modelId: number;
  onToggleSuccess?: () => void;
}

export const useFavoriteToggle = ({
  initialIsFavorite,
  modelId,
  onToggleSuccess,
}: IUseFavoriteToggleProps) => {
  const [localIsFavorite, setLocalIsFavorite] = useState(initialIsFavorite);
  const [isUpdating, setIsUpdating] = useState(false);

  const {
    mutate: favoriteModel,
    isLoading: isLoadingFavorite,
    isSuccess: isSuccessFavorite,
    reset: resetFavorite,
  } = useFavoriteModel();

  const {
    mutate: unfavoriteModel,
    isLoading: isLoadingUnfavorite,
    isSuccess: isSuccessUnfavorite,
    reset: resetUnfavorite,
  } = useUnfavoriteModel();

  const isLoading = isLoadingFavorite || isLoadingUnfavorite;

  useEffect(() => {
    if (initialIsFavorite !== localIsFavorite) {
      setIsUpdating(true);
    } else {
      setIsUpdating(false);
    }
  }, [initialIsFavorite, localIsFavorite]);

  useEffect(() => {
    if (isSuccessFavorite) {
      setLocalIsFavorite(true);
      onToggleSuccess?.();
      resetFavorite();
    } else if (isSuccessUnfavorite) {
      setLocalIsFavorite(false);
      onToggleSuccess?.();
      resetUnfavorite();
    }
  }, [isSuccessFavorite, isSuccessUnfavorite]);

  const toggleFavorite = () => {
    if (!isLoading && !isUpdating) {
      if (localIsFavorite) {
        unfavoriteModel(modelId);
      } else {
        favoriteModel(modelId);
      }
    }
  };

  return {
    isFavorite: localIsFavorite,
    isUpdating,
    isLoading,
    toggleFavorite,
  };
};
