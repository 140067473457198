import { FormikContextType, getIn } from 'formik';
import { useEffect, useState } from 'react';
import { IQuestion } from 'types/Question.types';
import { validateField } from 'utils/validation';

export const useValidateDateTime = (
  question: IQuestion,
  context: FormikContextType<any>
) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  useEffect(() => {
    if (question.required) {
      const fieldValue = getIn(context.values, question.name);
      const fieldName = question.name;
      const error = validateField(fieldValue, fieldName);

      if (error) {
        setErrorMessage(error);
        context.setFieldError(question.name, error);
      } else {
        setErrorMessage('');
        context.setFieldError(question.name, undefined);
      }
    } else {
      setErrorMessage('');
    }
  }, [getIn(context.values, question.name), question.required]);

  useEffect(() => {
    if (errorMessage) {
      context.setFieldError(question.name, errorMessage);
    } else {
      context.setFieldError(question.name, undefined);
    }
  }, [errorMessage]);
  return errorMessage;
};
