import { getIn } from 'formik';
import {
  Container,
  iconStyling,
  Option,
  OptionLabel,
} from './RadioInput.styled';
import { IQuestion } from 'types/Question.types';
import Icon from 'components/Icon/Icon';
import { Circle } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { useValidateRadioInput } from './hooks';

interface IRadioInputProps {
  pwId: string;
  question: IQuestion;
  context: any;
  isTouched: boolean;
  hasImages: boolean;
}

const RadioInput = ({ pwId, question, context }: IRadioInputProps) => {
  const selectedOptionValue = getIn(context.values, question.name);
  useValidateRadioInput(question, context);

  return (
    <Container data-testid={pwId} isRowFlexWrap={question.options.length <= 3}>
      {question.options.map((option: any[], index: number) => {
        const isChecked = selectedOptionValue === option[0];
        return (
          <Option
            key={index}
            onClick={() => context.setFieldValue(question.name, option[0])}
          >
            <Icon
              svg={Circle}
              color={COLORS.GREEN}
              weight={isChecked ? 'fill' : 'light'}
              size={isChecked ? 14 : 16}
              wrapperStyle={isChecked ? iconStyling : ''}
            />
            <OptionLabel>{option[0]}</OptionLabel>
          </Option>
        );
      })}
    </Container>
  );
};

export default RadioInput;
