import Toggle from 'components/Toggle/Toggle';
import DateInput from 'components/Input/DateInput/DateInput';
import CustomSelect from 'components/Select/FormikSelect';
import { FormikContextType, getIn, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { IQuestion, QuestionType } from 'types/Question.types';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import { DropdownType } from './constants';
import {
  getComponentsBasedOnDropdownType,
  getDropdownTypeFromListQuestion,
  getSelectOptionsFromQuestion,
  questionHasValue,
} from './helpers';
import OnBlurInputFormikText from 'components/Input/OnBlurInputFormik/OnBlurInputFormikText';
import OnBlurInputFormikNumber from 'components/Input/OnBlurInputFormik/OnBlurInputFormikNumber';
import { useIsTouched } from 'utils/hooks/useIsTouched';
import { setTouchedQuestionName } from 'store/Form/actions/form';

import RadioInput from 'components/Input/RadioInput/RadioInput';
import TableInput from 'components/Input/TableInput/TableInput';
import ColorInput from 'components/Input/ColorInput/ColorInput';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import {
  BooleanQuestionWrapper,
  StyledField,
  ColorInputWrapper,
} from './FormItem.styled';
import FormItemWrapper from '../FormItemWrapper/FormItemWrapper';

interface IFormItemProps {
  question: IQuestion;
  hasImages: boolean;
  questionPrice: number | undefined;
}

const FormItem = ({ question, hasImages, questionPrice }: IFormItemProps) => {
  const dispatch = useDispatch();
  const context: FormikContextType<any> = useFormikContext();
  const isTouched = useIsTouched(question.name);

  const { isSmallTablet } = useBreakpointFlag();

  return (
    <FormItemWrapper
      question={question}
      context={context}
      isTouched={isTouched}
      questionPrice={questionPrice}
    >
      {(() => {
        switch (question.type) {
          case QuestionType.NUMBER:
            return (
              <OnBlurInputFormikNumber
                pwId={'FormItem_' + question.name}
                question={question}
                context={context}
                isTouched={isTouched}
              />
            );
          case QuestionType.BOOLEAN: {
            const isChecked = context.values[question.name];
            return (
              <BooleanQuestionWrapper hasImages={hasImages}>
                <Toggle
                  pwId={'FormItem_' + question.name}
                  isChecked={isChecked}
                  onClick={() => {
                    const newValues = { ...context.values };
                    newValues[question.name] = !isChecked;
                    context.setValues(newValues);
                  }}
                />
              </BooleanQuestionWrapper>
            );
          }
          case QuestionType.LIST: {
            const dropdownType: DropdownType =
              getDropdownTypeFromListQuestion(question);
            return (
              <StyledField
                pwId={'FormItem_' + question.name}
                height={'40rem'}
                width={isSmallTablet ? '100%' : '250rem'}
                name={question.name}
                options={getSelectOptionsFromQuestion(question)}
                component={CustomSelect}
                translate={false}
                placeholder={capitalizeFirstLetter(question.name)}
                isMulti={question.multiple}
                components={getComponentsBasedOnDropdownType(dropdownType)}
                validate={(value: string) => {
                  if (question.required && !questionHasValue(value)) {
                    return `Invalid ${question.name} value`;
                  }
                }}
                onMenuClose={() => {
                  if (!isTouched) {
                    dispatch(setTouchedQuestionName(question.name));
                  }
                }}
                hasImages={hasImages}
              />
            );
          }
          case QuestionType.TABLE:
            return (
              <TableInput
                pwId={'FormItem_' + question.name}
                question={question}
                context={context}
                isTouched={isTouched}
              />
            );
          case QuestionType.DATETIME:
            return (
              <DateInput
                pwId={'FormItem_' + question.name}
                question={question}
                context={context}
                value={getIn(context.values, question.name)}
                isTouched={isTouched}
              />
            );
          case QuestionType.TEXT:
            return (
              <OnBlurInputFormikText
                pwId={'FormItem_' + question.name}
                question={question}
                context={context}
                isTouched={isTouched}
              />
            );
          case QuestionType.RADIO:
            return (
              <RadioInput
                pwId={'FormItem_' + question.name}
                question={question}
                context={context}
                isTouched={isTouched}
                hasImages={hasImages}
              />
            );
          case QuestionType.COLOR:
            return (
              <ColorInputWrapper hasImages={hasImages}>
                <ColorInput
                  pwId={'FormItem_' + question.name}
                  question={question}
                  context={context}
                  isTouched={isTouched}
                />
              </ColorInputWrapper>
            );
          default:
            return null;
        }
      })()}
    </FormItemWrapper>
  );
};

export default FormItem;
