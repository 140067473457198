import {
  borderRadiusSm,
  COLORS,
  marginLg,
  paddingSm,
  paddingXs,
} from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';
import { HAS_IMAGES_BREAKPOINT } from '../hooks';
import { ADDITIONAL_WRAPPER_MIN_HEIGHT } from './FormItemWrapper/FormItemWrapper.styled';

interface StyledProps {
  hasValue: boolean;
  showBorder: boolean;
  hasPrice: boolean;
  hasImages: boolean;
  opacityIndex: number;
  opacityCoeficient: number;
  hasRedStar?: boolean;
  isSkeleton?: boolean;
}

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 50%;
  width: 100%;
  height: 100%;

  ${(props: StyledProps) =>
    !props.hasImages &&
    css`
      width: 100%;
      max-width: 100%;
    `};

  @media screen and (max-width: ${HAS_IMAGES_BREAKPOINT}px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const FormContainer = styled.div``;

export const QuestionName = styled.label`
  width: max-content;
  font-size: 17rem;
  font-weight: 400;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `};
`;

export const FormItemContainer = styled.div`
  position: relative;
  display: flex;
  padding: ${paddingSm} ${paddingXs} 5rem;
  border-radius: ${borderRadiusSm};
  align-items: center;
  justify-content: space-between;

  :hover {
    color: ${COLORS.GREEN};
    background: ${COLORS.BACKGROUND};
    ${QuestionName} {
      color: ${COLORS.GREEN};
    }
  }
  ${(props: StyledProps) =>
    props.hasValue &&
    css`
      color: ${COLORS.GREEN};
      background: ${COLORS.BACKGROUND};
    `};

  ${respondTo.smallTablet`
      flex-wrap: wrap;
      gap: 5rem;
      padding: 15rem ${paddingXs} 5rem;
    `};
`;

export const ClearAllFieldsLabel = styled.label`
  position: relative;
  font-size: 17rem;
  line-height: 21rem;
  font-weight: 400;
  color: ${COLORS.RED_100};
  padding-right: ${paddingSm};
  cursor: pointer;
  user-select: none;
  z-index: 8;
  &:hover {
    text-decoration: underline;
  }

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const LabelsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const RedAsteriskWrapper = styled.label`
  position: relative;
  padding-left: 5rem;
  pointer-events: none;
  background-color: red;
  width: 17rem;
`;

export const RedAsterisk = styled.label`
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  font-weight: 400;
  font-size: 30rem;
  color: ${COLORS.RED_100};
  pointer-events: none;
`;

export const ModelNotFoundLabel = styled.label`
  text-align: center;
  width: 100%;
  font-size: 24rem;
  line-height: 32rem;
  font-weight: 400;
  margin-bottom: ${marginLg};
`;

export const QuestionNameAsteriskInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TooltipWrapper = styled.div`
  margin-left: 5rem;
`;

export const ContainerGrayBottomBorder = styled.div`
  ${(props: StyledProps) =>
    props.showBorder &&
    css`
      border-bottom: 1px solid ${COLORS.BORDER};
      border-width: thin;
    `};
  ${(props: StyledProps) =>
    props.opacityIndex !== undefined &&
    css`
      opacity: ${1 - (props.opacityIndex * props.opacityCoeficient + 0.2)};
    `};
  ${(props: StyledProps) =>
    props.isSkeleton &&
    css`
      padding-bottom: 24rem;
    `}
`;

export const AdditionalMargin = styled.div`
  // FormItemWrapper's AdditionalWrapper causes the question name to be off center,
  // this margin moves the question name towards the center of the FormItemContainer
  min-height: ${ADDITIONAL_WRAPPER_MIN_HEIGHT}rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${respondTo.smallTablet`
      display: none;
  `};
`;

export const Column = styled.div`
  // Used for positioning AdditionalMargin below the question name
  display: flex;
  flex-direction: column;
`;
