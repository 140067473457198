import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import styled from 'styled-components';
import { css } from 'styled-components';

interface StyledProps {
  isDarkMode?: boolean;
}

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  ${(props: StyledProps) =>
    props.isDarkMode
      ? css`
          background: radial-gradient(${COLORS.STRICT_BLACK}, ${COLORS.GREEN});
        `
      : css`
          background: radial-gradient(${COLORS.WHITE}, ${COLORS.GREEN});
        `}
`;

export const AnimatedLogoIcon = styled(Icon)`
  animation-name: stretch;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
  @keyframes stretch {
    0% {
      transform: scale(1.6);
    }
    100% {
      transform: scale(1.4);
    }
  }
`;
