import { COLORS } from 'assets/styled';
import { AnimatedLogoIcon, Container } from './LoadingScreenOverlay.styled';
import { ReactComponent as LogyxLogoSVG } from 'assets/icons/logyxLogo.svg';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface IProps {
  isDarkMode?: boolean;
}

const LoadingScreenOverlay = ({ isDarkMode }: IProps) => {
  const { isPhone } = useBreakpointFlag();

  return (
    <Container isDarkMode={isDarkMode}>
      <AnimatedLogoIcon
        svg={LogyxLogoSVG}
        alt=""
        fill={COLORS.BLACK}
        size={isPhone ? 150 : 200}
      />
    </Container>
  );
};

export default LoadingScreenOverlay;
