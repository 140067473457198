export const RoutesConfig = {
  // Company
  Models: {
    path: 'models',
    fullPath: '/models',
  },
  AllModels: {
    path: 'all-models',
    fullPath: '/all-models',
  },
  ShoppingCart: {
    path: 'cart',
    fullPath: '/cart',
  },

  // Supplier
  MyModels: {
    path: 'my-models',
    fullPath: '/my-models',
  },
  SupplierModelCompanies: {
    path: 'models',
    fullPath: '/models/:id',
  },
  SupplierCompanies: {
    path: 'companies',
    fullPath: '/companies',
  },
  SupplierSingleCompany: {
    path: 'companies/:id',
    fullPath: '/companies/:id',
  },

  // Dealer Panel
  DealerPanelSuppliers: {
    path: 'suppliers',
    fullPath: '/suppliers',
  },
  DealerPanelModels: {
    path: 'dp-models',
    fullPath: '/dp-models',
  },

  // Shared
  Profile: {
    path: 'profile',
    fullPath: '/profile',
  },
  Configure: {
    path: 'configure',
    fullPath: '/configure',
  },
  Users: {
    path: 'users',
    fullPath: '/users',
  },
  Settings: {
    path: 'settings',
    fullPath: '/settings',
  },

  // Auth
  Login: {
    path: '/auth/login',
    fullPath: '/auth/login',
  },
  Register: {
    path: '/auth/register',
    fullPath: '/auth/register',
  },
  SuccessfulRegistration: {
    path: '/auth/successful-registration',
    fullPath: '/auth/successful-registration',
  },
  ResetPassword: {
    path: '/auth/reset-password',
    fullPath: '/auth/reset-password',
  },
  RequestPasswordResetPage: {
    path: '/auth/request-password-reset',
    fullPath: '/auth/request-password-reset',
  },
  CompanySelect: {
    path: '/auth/company-select',
    fullPath: '/auth/company-select',
  },
  SupplierSelect: {
    path: '/auth/supplier-select',
    fullPath: '/auth/supplier-select',
  },
};
