export enum ReactMutationKeys {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  SUBMIT_CONFIGURATION_WITH_WEBHOOK = 'SUBMIT_CONFIGURATION_WITH_WEBHOOK',
  ADD_TO_SHOPPING_CART = 'ADD_TO_SHOPPING_CART',
  POST_CARD_ORDER = 'POST_CARD_ORDER',
  DELETE_SHOPPING_CART = 'DELETE_SHOPPING_CART',
  DELETE_SHOPPING_CART_ITEM = 'DELETE_SHOPPING_CART_ITEM',
  FAVORITE_MODEL = 'FAVORITE_MODEL',
  UNFAVORITE_MODEL = 'UNFAVORITE_MODEL',
  ENABLE_ORDER_SUPPLIER_COMPANY_MODEL = 'ENABLE_ORDER_SUPPLIER_COMPANY_MODEL',
  DISABLE_ORDER_SUPPLIER_COMPANY_MODEL = 'DISABLE_ORDER_SUPPLIER_COMPANY_MODEL',
  ENABLE_QUERY_SUPPLIER_COMPANY_MODEL = 'ENABLE_QUERY_SUPPLIER_COMPANY_MODEL',
  DISABLE_QUERY_SUPPLIER_COMPANY_MODEL = 'DISABLE_QUERY_SUPPLIER_COMPANY_MODEL',
  ENABLE_ALL_MODEL_ORDERING_FOR_SUPPLIER_COMPANY = 'ENABLE_ALL_MODEL_ORDERING_FOR_SUPPLIER_COMPANY',
  ENABLE_ALL_MODEL_QUERYING_FOR_SUPPLIER_COMPANY = 'ENABLE_ALL_MODEL_QUERYING_FOR_SUPPLIER_COMPANY',
  ENABLE_MODEL_ORDERING_FOR_ALL_SUPPLIER_COMPANIES = 'ENABLE_MODEL_ORDERING_FOR_ALL_SUPPLIER_COMPANIES',
  ENABLE_MODEL_QUERYING_FOR_ALL_SUPPLIER_COMPANIES = 'ENABLE_MODEL_QUERYING_FOR_ALL_SUPPLIER_COMPANIES',
  EDIT_SHOPPING_CART_ITEM = 'EDIT_SHOPPING_CART_ITEM',
  DUPLICATE_CART_LINE = 'DUPLICATE_CART_LINE',
  EDIT_USER_DATA = 'EDIT_USER_DATA',
  RESET_PASSWORD = 'RESET_PASSWORD',
  REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET',
  REGISTER = 'REGISTER',
  ADD_COMPANY_USER = 'ADD_COMPANY_USER',
  ADD_SUPPLIER_USER = 'ADD_SUPPLIER_USER',
  DELETE_SUPPLIER_USER = 'DELETE_SUPPLIER_USER',
  DELETE_COMPANY_USER = 'DELETE_COMPANY_USER',
  CREATE_SUPPLIER_COMPANY_MODEL = 'CREATE_SUPPLIER_COMPANY_MODEL',
  DELETE_SUPPLIER_COMPANY_MODEL = 'DELETE_SUPPLIER_COMPANY_MODEL',
  EDIT_SUPPLIER_COMPANY_REFERENCE = 'EDIT_SUPPLIER_COMPANY_REFERENCE',
  DELETE_SUPPLIER_COMPANY = 'DELETE_SUPPLIER_COMPANY',
  FETCH_USER_INFO = 'FETCH_USER_INFO',
  SUBMIT_VISIBILITY_CONFIGURATION = 'SUBMIT_VISIBILITY_CONFIGURATION',
  GET_DEALER_PANEL_MODEL_QUESTIONS = 'GET_DEALER_PANEL_MODEL_QUESTIONS',
  GET_MODEL_DOCUMENT_VISIBILITY = 'GET_MODEL_DOCUMENT_VISIBILITY',
  CREATE_CONFIGURATION = 'CREATE_CONFIGURATION',
  UPDATE_CONFIGURATION = 'UPDATE_CONFIGURATION',
  UPDATE_COMPANY_SETTINGS = 'UPDATE_COMPANY_SETTINGS',
  UPLOAD_COMPANY_LOGO = 'UPLOAD_COMPANY_LOGO',
  UPDATE_SUPPLIER_SETTINGS = 'UPDATE_SUPPLIER_SETTINGS',
  UPLOAD_SUPPLIER_LOGO = 'UPLOAD_SUPPLIER_LOGO',
  SELECT_ENTITIES = 'SELECT_ENTITIES',
}
