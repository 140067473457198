import { capitalizeFirstLetter } from './stringUtils';

export const isValidEmail = (email: string) => {
  const re = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}/;
  return re.test(String(email).toLowerCase());
};

export const isNumeric = (num: any) =>
  (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) &&
  !isNaN(num as number);

export function validateOptionalNumericField(
  value: string,
  fieldName: string,
  min: number,
  max: number
) {
  if (value === '' || value === undefined || value === null) {
    return null;
  } else if (!isNumeric(value)) {
    return `${capitalizeFirstLetter(fieldName)} must be a number`;
  }
  if (Number(value) < min) {
    return `${capitalizeFirstLetter(fieldName)} cant be smaller than ${min}`;
  } else if (Number(value) > max) {
    return `${capitalizeFirstLetter(fieldName)} cant be larger than ${max}`;
  }
}

export function validateNumericField(
  value: string,
  fieldName: string,
  min: number,
  max: number
) {
  if (value === '' || value === undefined || value === null) {
    return `${capitalizeFirstLetter(fieldName)} is invalid`;
  } else if (!isNumeric(value)) {
    return `${capitalizeFirstLetter(fieldName)} must be a number`;
  }
  if (min !== null && Number(value) < min) {
    return `${capitalizeFirstLetter(fieldName)} cant be smaller than ${min}`;
  } else if (max !== null && Number(value) > max) {
    return `${capitalizeFirstLetter(fieldName)} cant be larger than ${max}`;
  }
}

export function validateField(value: string, fieldName: string) {
  if (value === '' || value === undefined || value === null) {
    return `${capitalizeFirstLetter(fieldName)} is invalid`;
  }
}

export function validateRadioInput(value: string, fieldName: string) {
  if (value === '' || value === undefined || value === null) {
    return `${capitalizeFirstLetter(fieldName)} must be selected`;
  }
}

export function validateTableInput(value: any, fieldName: string) {
  if (
    value === '' ||
    value === undefined ||
    value === null ||
    (Array.isArray(value) && !value.length)
  ) {
    return `${capitalizeFirstLetter(fieldName)} must be selected`;
  }
}

export function validateColorInput(value: any, fieldName: string) {
  if (
    value === '' ||
    value === undefined ||
    value === null ||
    (Array.isArray(value) && !value.length)
  ) {
    return `${capitalizeFirstLetter(fieldName)} must be selected`;
  }
}
