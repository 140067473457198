import { getIn } from 'formik';
import {
  ChosenOptionLabel,
  ChosenOptionsWrapper,
  Container,
} from './ColorInput.styled';
import { IQuestion } from 'types/Question.types';
import Button from 'components/Button/Button';
import Tooltip from 'components/Tooltip/Tooltip';
import { getTooltipText } from 'pages/ConfigurePage/ConfigureForm/FormItem/helpers';
import { useDispatch } from 'react-redux';
import { setTouchedQuestionName } from 'store/Form/actions/form';
import { useTranslation } from 'react-i18next';
import { setColorModalData } from 'store/ColorModal/actions/colorModal';
import { IColor } from 'components/Modals/ColorModal/ColorModal';
import { getChosenColorOptionFromQuestionOptions } from './helpers';
import { useValidateColorInput } from './hooks';

interface IColorInputProps {
  pwId: string;
  question: IQuestion;
  context: any;
  isTouched: boolean;
}

const ColorInput = ({ question, context, isTouched }: IColorInputProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const chosenColorValue: string | undefined = getIn(
    context.values,
    question.name
  );
  const chosenColor: IColor | undefined =
    getChosenColorOptionFromQuestionOptions(chosenColorValue, question);
  useValidateColorInput(question, context);

  return (
    <Container>
      <Button
        label={t('Choose')}
        primary
        width="150rem"
        onClick={() => {
          dispatch(setColorModalData(question));
          if (!isTouched) {
            dispatch(setTouchedQuestionName(question.name));
          }
        }}
      />
      <ChosenOptionsWrapper>
        {chosenColor ? (
          <Tooltip
            content={getTooltipText(
              Object.keys(chosenColor),
              Object.values(chosenColor),
              t
            )}
          >
            <ChosenOptionLabel>{chosenColor.name}</ChosenOptionLabel>
          </Tooltip>
        ) : null}
      </ChosenOptionsWrapper>
    </Container>
  );
};

export default ColorInput;
