import { FieldProps } from 'formik';
import { GroupBase } from 'react-select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import {
  ErrorMessageWrapper,
  RelativeDiv,
  SelectWrapper,
  StyledReactSelect,
} from './Select.styled';
import { useTranslation } from 'react-i18next';

interface Option {
  label: string;
  value: string;
}

interface CustomSelectProps extends FieldProps {
  width?: string;
  height?: string;
  options: Array<Option>;
  isMulti: boolean;
  className?: string;
  placeholder?: string;
  selectedValue?: string;
  onSelect?: (option: any) => void;
  onInputChange?: (value: string) => void;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
  errorMessage: string;
  disabled: boolean;
  components?:
    | Partial<SelectComponents<unknown, boolean, GroupBase<unknown>>>
    | undefined;
  pwId?: string;
  onMenuScrollToBottom?: () => void;
  translate?: boolean;
  hasImages?: boolean;
}

export const CustomSelect = ({
  width = '200rem',
  height = '40rem',
  className,
  placeholder,
  field,
  form,
  options,
  isMulti,
  selectedValue,
  onSelect,
  onInputChange,
  onMenuClose,
  onMenuOpen,
  errorMessage,
  disabled,
  components,
  pwId,
  onMenuScrollToBottom,
  translate = true,
  hasImages,
}: CustomSelectProps) => {
  const onChange = (option: Option | Option[]) => {
    onSelect && onSelect(option);
    form.setFieldValue(
      field.name,
      isMulti
        ? (option as Option[]).map((item: Option) => item.value)
        : (option as Option).value
    );
  };

  const getValue = () => {
    if (options) {
      if (isMulti) {
        return options.filter(
          (option: Option) => field && field?.value?.indexOf(option?.value) >= 0
        );
      } else {
        const existingOption = options.find(
          (option: Option) =>
            option.value === field.value ||
            option.value === selectedValue?.toString()
        );
        if (existingOption) {
          return existingOption;
        } else {
          if (form.values[field.name]) {
            // If an option has been selected, but has been removed from total options, remove the value from formik
            form.setFieldValue(field.name, undefined);
          }
          return null; // Resets the displayed value in the select if option has been removed
        }
      }
    } else {
      return isMulti ? [] : ('' as string);
    }
  };

  const { t } = useTranslation();
  const formatOptionLabel = ({ label }: any) => {
    return (
      <div style={{ display: 'flex' }}>{translate ? t(label) : label}</div>
    );
  };

  return (
    <SelectWrapper>
      <RelativeDiv data-testid={pwId}>
        <StyledReactSelect
          width={width}
          height={height}
          className={className}
          formatOptionLabel={formatOptionLabel}
          defaultValue={getValue()}
          name={field.name}
          value={getValue()}
          onChange={onChange}
          classNamePrefix="select"
          // menuIsOpen={true}
          placeholder={placeholder}
          options={options}
          isMulti={isMulti}
          isDisabled={disabled}
          isError={errorMessage !== '' && typeof errorMessage === 'string'}
          onInputChange={(value: any) => {
            onInputChange && onInputChange(value);
          }}
          components={components}
          onMenuClose={() => {
            onMenuClose && onMenuClose();
          }}
          onMenuOpen={() => {
            onMenuOpen && onMenuOpen();
          }}
          onMenuScrollToBottom={onMenuScrollToBottom}
        />
        <ErrorMessageWrapper hasImages={hasImages}>
          {errorMessage}
        </ErrorMessageWrapper>
      </RelativeDiv>
    </SelectWrapper>
  );
};

export default CustomSelect;
