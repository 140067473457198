export interface IQuestion {
  name: string;
  default: string[];
  description: string | null;
  group: string | null;
  hidden: boolean;
  inputs: string[];
  multiple: boolean;
  options: [];
  order: string | null;
  outputs: object;
  required: boolean;
  type: QuestionType;
  min: number;
  max: number;
  initialKey: string; // Name of question key that gets overriden in case question name is provided
}

export enum QuestionType {
  NUMBER = 'number',
  LIST = 'list',
  TABLE = 'table',
  BOOLEAN = 'boolean',
  TEXT = 'text',
  DATETIME = 'datetime',
  RADIO = 'radio',
  COLOR = 'color',
}
