import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { IQuestion, QuestionType } from 'types/Question.types';
import {
  getValuesForMultipleListQuestion,
  getValuesForSingleListQuestion,
  getValuesForSingleTableQuestion,
  getValuesForMultipleTableQuestion,
  formatValuesFromOutputs,
  getValuesForRadioQuestion,
  getValuesForColorQuestion,
} from './helpers';
import moment from 'moment';
import { Dispatch, useEffect } from 'react';
import { AnyAction } from 'redux';
import {
  triggerAddToShoppingCart,
  triggerEditCartItem,
} from 'store/ShoppingCart/actions/shoppingCart';
import { triggerSubmitConfiguration } from 'store/SubmitConfiguration/actions/submitConfiguration';
import { resetConfigureForm } from 'store/Model/actions/model';
import { useMutation } from 'react-query';
import { submitConfiguration } from 'services/ExternalService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import {
  useAddToShoppingCart,
  useEditShoppingCartItem,
} from 'pages/ShoppingCartPage/hooks';
import {
  SET_TRIGGER_SHOW_ADDED_TO_CART_MODAL,
  SET_TRIGGER_SHOW_CART_ITEM_EDITED_MODAL,
} from 'store/constants';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  IMobileMessage,
  MobileMessageTypes,
  postMobileMessage,
} from 'utils/mobile/postMobileMessage';

// Generate values for the form based of question outputs of the query response
// (Or from initial getQuestions response outputs if no query has been made yet)
export const useGetValuesFromQuestionOutputs = () => {
  const valuesFromOutputs = {};
  const questions = useSelector(
    (state: IRootReducerState) => state.modelInfo.questions
  );
  for (const question of questions) {
    if (question.hidden) {
      continue;
    }
    switch (question.type) {
      case QuestionType.BOOLEAN:
        if (question.outputs) {
          // If output value was null, the boolean will be set to false
          valuesFromOutputs[question.name] = !!question.outputs.value;
        } else {
          valuesFromOutputs[question.name] = '';
        }
        break;
      case QuestionType.DATETIME:
        if (question.outputs && question.outputs.value) {
          valuesFromOutputs[question.name] = moment(
            question.outputs.value
          ).toDate();
        } else {
          valuesFromOutputs[question.name] = '';
        }
        break;
      case QuestionType.LIST:
        if (question.outputs) {
          if (question.multiple) {
            valuesFromOutputs[question.name] =
              getValuesForMultipleListQuestion(question);
          } else {
            valuesFromOutputs[question.name] =
              getValuesForSingleListQuestion(question);
          }
        } else {
          valuesFromOutputs[question.name] = '';
        }
        break;
      case QuestionType.NUMBER:
        if (question.outputs && question.outputs?.value !== null) {
          valuesFromOutputs[question.name] = question.outputs.value?.toString();
        } else {
          valuesFromOutputs[question.name] = '';
        }
        break;
      case QuestionType.TEXT:
        if (question.outputs && question.outputs.value) {
          valuesFromOutputs[question.name] = question.outputs.value;
        } else {
          valuesFromOutputs[question.name] = '';
        }
        break;
      case QuestionType.TABLE:
        if (question.outputs) {
          if (question.multiple) {
            valuesFromOutputs[question.name] =
              getValuesForMultipleTableQuestion(question);
          } else {
            valuesFromOutputs[question.name] =
              getValuesForSingleTableQuestion(question);
          }
        } else {
          valuesFromOutputs[question.name] = '';
        }
        break;
      case QuestionType.RADIO:
        if (question.outputs) {
          valuesFromOutputs[question.name] =
            getValuesForRadioQuestion(question);
        } else {
          valuesFromOutputs[question.name] = '';
        }
        break;
      case QuestionType.COLOR:
        if (question.outputs) {
          valuesFromOutputs[question.name] =
            getValuesForColorQuestion(question);
        } else {
          valuesFromOutputs[question.name] = '';
        }
        break;
    }
  }

  return valuesFromOutputs;
};

export const useListenForAddToCartTrigger = (
  queryPayload: object,
  dispatch: Dispatch<AnyAction>
) => {
  const shouldAddCurrentFormValuesToShoppingCart: boolean = useSelector(
    (state: IRootReducerState) =>
      state.shoppingCartInfo.triggerAddToShoppingCart
  );
  const company = useSelector(
    (state: IRootReducerState) => state.userInfo.company
  );
  const modelInfo = useSelector((state: IRootReducerState) => state.modelInfo);
  const { mutate: addToCart, isSuccess: addToCartSuccess } =
    useAddToShoppingCart();

  useEffect(() => {
    if (addToCartSuccess) {
      dispatch({ type: SET_TRIGGER_SHOW_ADDED_TO_CART_MODAL, payload: true });
    }
  }, [addToCartSuccess]);

  useEffect(() => {
    if (shouldAddCurrentFormValuesToShoppingCart) {
      addToCart({
        modelId: modelInfo.id,
        companyId: company.id,
        lang: modelInfo.lang,
        queryPayload: queryPayload,
      });
      dispatch(triggerAddToShoppingCart(false));
    }
  }, [shouldAddCurrentFormValuesToShoppingCart]);
};

export const useListenForEditCartItemTrigger = (
  queryPayload: object,
  dispatch: Dispatch<AnyAction>
) => {
  const {
    triggerEditCartItem: shouldEditCartItem,
    currentlyEditingShoppingCartLineId: cartLineId,
  } = useSelector((state: IRootReducerState) => state.shoppingCartInfo);
  const company = useSelector(
    (state: IRootReducerState) => state.userInfo.company
  );
  const modelInfo = useSelector((state: IRootReducerState) => state.modelInfo);
  const { mutate: editCartItem, isSuccess: cartItemEditedSuccess } =
    useEditShoppingCartItem();

  useEffect(() => {
    if (cartItemEditedSuccess) {
      dispatch({
        type: SET_TRIGGER_SHOW_CART_ITEM_EDITED_MODAL,
        payload: true,
      });
    }
  }, [cartItemEditedSuccess]);

  useEffect(() => {
    if (shouldEditCartItem) {
      editCartItem({
        cartLineId,
        modelId: modelInfo.id,
        companyId: company.id,
        lang: modelInfo.lang,
        queryPayload: queryPayload,
      });
      dispatch(triggerEditCartItem(false));
    }
  }, [shouldEditCartItem]);
};

export const useGetSortedQuestions = () => {
  const initialQuestions = useSelector(
    (state: IRootReducerState) => state.modelInfo.questions
  );
  const sortedQuestions = [...initialQuestions].sort((a, b) => {
    const orderA = a.order !== undefined ? a.order : Infinity;
    const orderB = b.order !== undefined ? b.order : Infinity;
    return orderA - orderB;
  });
  return sortedQuestions;
};

export interface IConfiguredProductData {
  modelId: number | null;
  versionNumber: number | null;
  pricing: object | null;
  roundPricing: boolean;
  questions: IQuestion[];
  general: object | null;
  valuesFromOutputs: object;
  lang: string;
  queryPayload: object; // Payload that was last sent to query the model before form submission
}

export const useListenForSubmitConfigurationTrigger = (
  valuesFromOutputs: object,
  dispatch: Dispatch<AnyAction>
) => {
  const isFormValid = useSelector(
    (state: IRootReducerState) => state.formInfo.isFormValid
  );
  const isQueryRequestAttempt = useSelector(
    (state: IRootReducerState) => state.modelInfo.queryStatus.success
  );
  const shouldSubmitConfiguration: boolean = useSelector(
    (state: IRootReducerState) =>
      state.submitConfigurationInfo.triggerSubmitConfiguration
  );
  const base64Metadata: string | undefined = useSelector(
    (state: IRootReducerState) => state.submitConfigurationInfo.base64Metadata
  );
  const modelInfo = useSelector((state: IRootReducerState) => state.modelInfo);

  const { mutate: submitConfiguration, isSuccess: submitConfigurationSuccess } =
    useSubmitConfigurationWithWebhook();

  useEffect(() => {
    if (isFormValid && shouldSubmitConfiguration && !isQueryRequestAttempt) {
      const configurationData: IConfiguredProductData = {
        modelId: modelInfo.id,
        versionNumber: modelInfo.versionNumber,
        pricing: modelInfo.pricing,
        roundPricing: modelInfo.roundPricing,
        questions: modelInfo.questions,
        general: modelInfo.general,
        valuesFromOutputs: formatValuesFromOutputs(valuesFromOutputs),
        lang: modelInfo.lang,
        queryPayload: modelInfo.queryPayload,
      };
      const dto: ISubmitConfigurationWithWebhookDTO = {
        data: configurationData,
        base64Metadata,
      };
      submitConfiguration(dto);
      dispatch(triggerSubmitConfiguration(false));
    }
  }, [shouldSubmitConfiguration, isQueryRequestAttempt]);

  const isMobileApp = useSelector(
    (state: IRootReducerState) => state.commonInfo.isMobileApp
  );

  useEffect(() => {
    if (submitConfigurationSuccess) {
      if (isMobileApp) {
        const mobileMessage: IMobileMessage = {
          type: MobileMessageTypes.CLOSE_LOGYX,
        };
        postMobileMessage(mobileMessage);
      }
      window.close();
      dispatch(resetConfigureForm());
    }
  }, [submitConfigurationSuccess]);
};

export interface ISubmitConfigurationWithWebhookDTO {
  data: IConfiguredProductData;
  base64Metadata?: string;
}

const useSubmitConfigurationWithWebhook = () => {
  const { t } = useTranslation();
  return useMutation(
    (dto: ISubmitConfigurationWithWebhookDTO) =>
      submitConfiguration(dto.data, dto.base64Metadata),
    {
      onSuccess: () => {
        // Success
      },
      onError: () => {
        toast.error(t('Failed to submit configuration'));
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.SUBMIT_CONFIGURATION_WITH_WEBHOOK,
      retry: 3, // Retry the mutation 3 times on failure
      retryDelay: 2000, // Wait 2000 ms between retries
    }
  );
};
