import apiClient from './api/apiService';
import { getUrlWithQueryParams } from './api/getUrlWithQueryParams';

const BASE_URL = '/v1/categories';

export const getCategories = async (
  supplierId: number | null,
  page: number,
  perPage: number,
  searchBy: string
): Promise<any> => {
  const url = `${BASE_URL}`;
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    search_term: searchBy,
    supplier_id: supplierId,
  };
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};
