import {
  CSSProperties,
  InputHTMLAttributes,
  MouseEventHandler,
  useRef,
} from 'react';
import {
  Container,
  InputLabel,
  StyledInput,
  ErrorMessage,
  StyledTextareaInput,
  IconWrap,
  StyledIcon,
} from './Input.styled';
import NumberInputArrows from './NumberInputArrows/NumberInputArrows';
import { COLORS } from 'assets/styled';
import { SymbolPlacementEnum } from './PriceInput/PriceInput';

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  style?: CSSProperties;
  error?: boolean;
  errorMessage?: string;
  wrapperStyles?: CSSProperties;
  isTextArea?: boolean;
  icon?: any;
  placeholder: string;
  onIncrease?: (value: string) => void; // Used when type "number" is provided
  onDecrease?: (value: string) => void; // Used when type "number" is provided
  onMouseEnter?: MouseEventHandler<HTMLInputElement>; // Used when type "number" is provided
  onMouseLeave?: MouseEventHandler<HTMLInputElement>; // Used when type "number" is provided
  pwId?: string;
  iconPlacement?: SymbolPlacementEnum;
}
const Input = ({
  onIncrease,
  onDecrease,
  onMouseEnter,
  onMouseLeave,
  ...props
}: IInput) => {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  return (
    <Container
      wrapperStyles={props?.wrapperStyles}
      onClick={() => {
        inputRef?.current?.focus();
      }}
    >
      {props.label && (
        <InputLabel type={props.type} htmlFor={props.name}>
          {props.label}
        </InputLabel>
      )}
      {props.isTextArea ? (
        <StyledTextareaInput
          data-testid={props['pwId']}
          ref={inputRef}
          {...props}
          id={props.name}
          style={props.style}
          onChange={(e: any) => {
            props.onChange && props.onChange(e);
          }}
          onBlur={(e: any) => {
            props.onBlur && props.onBlur(e);
          }}
          type={props.type || 'text'}
          spellcheck="false"
          placeholder={props.placeholder}
          isError={!!props.errorMessage}
        />
      ) : (
        <>
          <StyledInput
            data-testid={props['pwId']}
            ref={inputRef}
            {...props}
            id={props.name}
            style={props.style}
            onChange={(e: any) => {
              props.onChange && props.onChange(e);
            }}
            onBlur={(e: any) => {
              props.onBlur && props.onBlur(e);
            }}
            type={props.type || 'text'}
            placeholder={props.placeholder}
            isError={!!props.errorMessage}
            countArrows={onIncrease || onDecrease}
          />
          {props.icon && (
            <IconWrap iconPlacement={props.iconPlacement}>
              <StyledIcon
                svg={props.icon}
                size={20}
                fill={COLORS.BLACK}
                pointer={false}
                weight="regular"
              />
            </IconWrap>
          )}
          {props.type === 'number' && (
            <NumberInputArrows
              onIncrease={onIncrease}
              onDecrease={onDecrease}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            />
          )}
        </>
      )}
      {props.error && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    </Container>
  );
};

export default Input;
