import { IUpdateSupplierSettingsDTO } from 'pages/SettingsPage/SettingsRow/hooks';
import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';

const BASE_URL = '/v1/suppliers';

export const getSuppliers = async (
  page: number,
  perPage: number,
  searchBy: string
): Promise<any> => {
  const url = `${BASE_URL}`;
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    search: searchBy,
  };
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getSupplierCompanies = async (
  supplierId: number,
  page: number,
  perPage: number,
  searchBy: string
): Promise<any> => {
  const queryParamsObj = {
    page,
    per_page: perPage,
    search_term: searchBy,
  };
  const url = `${BASE_URL}/${supplierId}/companies`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getSupplierUsers = async (
  supplierId: number,
  page: number,
  perPage: number,
  searchBy: string
): Promise<any> => {
  const queryParamsObj = {
    page,
    per_page: perPage,
    search_term: searchBy,
  };
  const url = `${BASE_URL}/${supplierId}/users`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const addSupplierUser = async (
  email: string,
  supplierId: number
): Promise<any> => {
  const url = `${BASE_URL}/${supplierId}/users`;
  const { data } = await apiClient.post<any>(url, {
    email,
  });
  // Need the response message to show alternative toast if registration link was sent
  return data;
};

export const deleteSupplierUser = async (
  userId: number,
  supplierId: number
): Promise<any> => {
  const url = `${BASE_URL}/${supplierId}/users/${userId}`;
  const { data } = await apiClient.delete<any>(url);

  return data.data;
};

export const updateSupplierSettings = async (
  supplierId: number,
  dto: IUpdateSupplierSettingsDTO
): Promise<any> => {
  const url = `${BASE_URL}/${supplierId}`;
  const { data } = await apiClient.patch<any>(url, dto);

  return data.data;
};

export const uploadSupplierLogo = async (
  supplierId: number,
  formData: FormData
) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const url = `${BASE_URL}/${supplierId}/logo`;
  const { data } = await apiClient.post(url, formData, { headers });

  return data.data;
};

export const getSupplier = async (supplierId: number): Promise<any> => {
  const url = `${BASE_URL}/${supplierId}`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};
