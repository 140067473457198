import { COLORS, gapSm } from 'assets/styled';
import styled from 'styled-components';

interface StyledProps {
  isActive: boolean;
  isDisabled: boolean;
  defaultCursor: boolean;
  isEllipsis: boolean;
}

export const PAGINATION_HEIGHT = 32;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${gapSm};
  align-items: center;
  justify-content: center;
`;

export const Square = styled.div`
  user-select: none;
  min-width: 32rem;
  height: 32rem;
  padding-left: 4rem;
  padding-right: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14rem;
  font-weight: 700;
  border-radius: 20rem;
  border: 1rem solid ${COLORS.WHITE};
  background-color: ${COLORS.WHITE};
  border-color: ${(props: StyledProps) => {
    if (props.isActive) {
      return COLORS.GREEN;
    }
    if (props.isDisabled) {
      return COLORS.BORDER;
    }
    return COLORS.GREEN_DISABLED;
  }};
  color: ${(props: StyledProps) => {
    if (props.isActive) {
      return COLORS.GREEN;
    }
    return COLORS.BLACK;
  }};
  cursor: ${(props: StyledProps) =>
    props.defaultCursor
      ? 'default'
      : props.isDisabled
      ? 'not-allowed'
      : 'pointer'};
  &:hover {
    border-color: ${(props: StyledProps) =>
      !props.isEllipsis &&
      !props.isDisabled &&
      !props.isActive &&
      COLORS.GREEN};
  }
`;
