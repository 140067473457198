import styled, { css } from 'styled-components';
import { borderRadiusSm, COLORS, transition } from 'assets/styled';
import { CSSProperties } from 'react';
import { ButtonBase } from '@mui/material';

interface StyledProps {
  primary: boolean;
  secondary: boolean;
  table: boolean;
  red: boolean;
  redOutline: boolean;
  link: boolean;
  width: string;
  height: string;
  color: string;
  block: boolean;
  wrapperStyle: CSSProperties;
  buttonStyle: CSSProperties;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:has(svg) {
    padding: 6rem;
    border-radius: ${borderRadiusSm};
  }

  ${(props: StyledProps) => props.wrapperStyle};
`;

export const StyledButton = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  padding: 12rem 16rem 12rem 14rem;
  font-weight: 700;
  line-height: 16rem;
  font-size: 12rem;
  border-radius: ${borderRadiusSm};
  border: none;
  height: 40rem;
  width: ${(props: StyledProps) => props.width};
  transition: ${transition};

  &:disabled {
    pointer-events: all;
    cursor: not-allowed !important;
  }
  color: ${(props: StyledProps) => props.color || '#1f2937'};

  ${(props: StyledProps) =>
    props.primary &&
    css`
      background-color: ${COLORS.GREEN};
      color: ${COLORS.STRICT_WHITE};
      gap: 10rem;
      height: 40rem;
      width: ${props.width};
      font-weight: 300;
      font-size: 14rem;
      &:hover:enabled {
        box-shadow: 0 0 0 1rem ${COLORS.GREEN};
      }
      &:disabled {
        background-color: ${COLORS.GREEN_DISABLED};
      }
    `}

  ${(props: StyledProps) =>
    props.secondary &&
    css`
      border: 1rem solid ${COLORS.GREEN};
      background-color: ${COLORS.WHITE};
      color: ${COLORS.GREEN};
      font-weight: 300;
      width: ${props.width};
      font-size: 14rem;

      &:hover:enabled {
        color: ${COLORS.STRICT_WHITE};
        background-color: ${COLORS.GREEN};
        box-shadow: 0 0 0 1rem ${COLORS.GREEN};
      }

      &:disabled {
        opacity: 0.5;
      }
    `}


  ${(props: StyledProps) =>
    props.height &&
    css`
      height: ${(props: StyledProps) => props.height};
    `};

  ${(props: StyledProps) =>
    props.red &&
    css`
      background-color: ${COLORS.RED};
      font-weight: 300;
      color: ${COLORS.STRICT_WHITE};
      &:disabled {
        background-color: ${COLORS.RED + '90'};
      }
    `};

  ${(props: StyledProps) =>
    props.redOutline &&
    css`
      font-size: 14rem;
      line-height: 21rem;
      font-weight: 300;
      background-color: #edf4fa;
      border: 1rem solid ${COLORS.RED};
      color: ${COLORS.STRICT_BLACK};
      &:hover {
        background-color: ${COLORS.RED};
        color: ${COLORS.STRICT_WHITE};
      }
    `};

  ${(props: StyledProps) => props.buttonStyle};
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 15rem;
  transform: translateY(-50%);
`;
