import { FormikContextType, getIn } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { IQuestion } from 'types/Question.types';
import { validateRadioInput } from 'utils/validation';

export const useValidateRadioInput = (
  question: IQuestion,
  context: FormikContextType<any>
) => {
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (question.required) {
      const fieldValue = getIn(context.values, question.name);
      const fieldName = question.name;

      const error = validateRadioInput(fieldValue, fieldName);

      if (error) {
        setErrorMessage(error);
        context.setFieldError(question.name, error);
      } else {
        setErrorMessage('');
        context.setFieldError(question.name, undefined);
      }
    } else {
      setErrorMessage('');
    }
  }, [getIn(context.values, question.name), question.required]);

  const { isFormValid } = useSelector(
    (state: IRootReducerState) => state.formInfo
  );
  useEffect(() => {
    if (errorMessage) {
      context.setFieldError(question.name, errorMessage);
    } else {
      context.setFieldError(question.name, undefined);
    }
  }, [errorMessage, isFormValid]);
  return errorMessage;
};
