import { FormikContextType, getIn } from 'formik';
import { useEffect, useState } from 'react';
import { IQuestion } from 'types/Question.types';
import {
  validateNumericField,
  validateOptionalNumericField,
} from 'utils/validation';

export const useValidateTextAfterValueChange = (
  textValue: string,
  question: IQuestion,
  context: FormikContextType<any>
) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  useEffect(() => {
    let error;
    if (question.required || question.min !== null || question.max !== null) {
      error = validateNumericField(
        textValue?.length.toString(),
        question.name,
        question.min,
        question.max
      );
    } else {
      error = validateOptionalNumericField(
        textValue?.length.toString(),
        question.name,
        question.min,
        question.max
      );
    }
    if (error) {
      setErrorMessage(error);
      context.setFieldError(question.name, error);
    } else {
      setErrorMessage('');
      context.setFieldError(question.name, undefined);
    }
  }, [
    textValue,
    getIn(context.values, question.name),
    getIn(context.errors, question.name),
  ]);

  useEffect(() => {
    if (errorMessage) {
      context.setFieldError(question.name, errorMessage);
    } else {
      context.setFieldError(question.name, undefined);
    }
  }, [errorMessage]);

  return errorMessage;
};

export const useValidateNumberAfterValueChange = (
  fieldValue: string | number,
  question: IQuestion,
  context: FormikContextType<any>
) => {
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    let error;
    if (question.required || question.min !== null || question.max !== null) {
      error = validateNumericField(
        fieldValue?.toString(),
        question.name,
        question.min,
        question.max
      );
    } else {
      error = validateOptionalNumericField(
        fieldValue?.toString(),
        question.name,
        question.min,
        question.max
      );
    }
    if (error) {
      setErrorMessage(error);
      context.setFieldError(question.name, error);
    } else {
      setErrorMessage('');
      context.setFieldError(question.name, undefined);
    }
  }, [
    fieldValue,
    getIn(context.values, question.name),
    getIn(context.errors, question.name),
    question?.max,
    question?.min,
  ]);

  useEffect(() => {
    if (errorMessage) {
      context.setFieldError(question.name, errorMessage);
    } else {
      context.setFieldError(question.name, undefined);
    }
  }, [errorMessage]);

  return errorMessage;
};
