import Input from 'components/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { Modal } from '../Modal/Modal';
import {
  Column,
  Container,
  Divider,
  FirstRow,
  FlexWrap,
  InputWrapper,
  PopularFlexWrap,
  PopularSection,
  ResultsSection,
  SelectWrapper,
  Title,
} from './ColorModal.styled';
import { MagnifyingGlass as Search } from '@phosphor-icons/react';
import { getIn, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  extractColorCollectionOptions,
  extractColorsFromQuestion,
  filterColorsByCollectionAndSearchTerm,
  getIsColorSelected,
} from './helpers';
import { IColorModalReducerState } from 'store/ColorModal/reducers/colorModal';
import { closeColorModal } from 'store/ColorModal/actions/colorModal';
import { Select } from 'components/Select/Select';
import { IOption } from 'components/Select/type';
import ColorCard from './ColorCard/ColorCard';
import PopularColorCard from './PopularColorCard/PopularColorCard';
import { getChosenColorOptionFromQuestionOptions } from 'components/Input/ColorInput/helpers';
import { getIndexesOfInputsInOutputs } from 'pages/ConfigurePage/ConfigureForm/helpers';

export interface IColor {
  additional_price: string;
  collection: string;
  description: string;
  hex_code: string;
  image: string;
  name: string;
  popular: string;
  code: string;
}

export function ColorModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedCollection, setSelectedCollection] = useState<string>('');
  const [colorSearchBy, setColorSearchBy] = useState<string>('');

  const { isOpen, question }: IColorModalReducerState = useSelector(
    (state: IRootReducerState) => state.colorModalInfo
  );
  const context = useFormikContext();

  if (!question || !context) {
    return null; // Modal not opened
  }

  const handleChooseColor = (color: IColor) => {
    const indexesOfInputsInOutputs: number[] = getIndexesOfInputsInOutputs(
      question.inputs,
      question.outputs
    );
    const colorValues = indexesOfInputsInOutputs.map(
      (index: number) => Object.values(color)[index]
    );
    context.setFieldValue(question.name, colorValues);
    handleCancel();
  };
  const handleCancel = () => {
    setColorSearchBy('');
    dispatch(closeColorModal());
  };

  const chosenColorValue: string | undefined = getIn(
    context.values,
    question.name
  );
  const chosenColor: IColor | undefined =
    getChosenColorOptionFromQuestionOptions(chosenColorValue, question);

  const extractedColors: IColor[] = extractColorsFromQuestion(question);
  const colorCollectionOptions: IOption[] =
    extractColorCollectionOptions(extractedColors);

  const colors: IColor[] = filterColorsByCollectionAndSearchTerm(
    extractedColors,
    selectedCollection,
    colorSearchBy
  );

  const popularColors: IColor[] = colors.filter(
    (color: IColor) => color.popular === 'true'
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => handleCancel()}
      modalStyle={{
        position: 'fixed',
        margin: 'auto',
        maxWidth: '1340rem',
      }} // Center positioning
    >
      <Container>
        <FirstRow>
          <Column>
            <Title>{t('Find colour by name or code')}</Title>
            <InputWrapper>
              <Input
                icon={Search}
                placeholder={t('Search')}
                width={'100%'}
                wrapperStyles={{ width: '100%' }}
                onChange={(e: any) => setColorSearchBy(e.target.value)}
              />
            </InputWrapper>
          </Column>
          <Divider />
          <Column>
            <Title>{t('Select a colour collection')}</Title>
            <SelectWrapper>
              <Select
                width={'100%'}
                name="collection-select"
                placeholder={t('Choose')}
                options={colorCollectionOptions}
                defaultValue={{ label: 'All collections', value: '' }}
                onChange={(selectedOption: any) =>
                  setSelectedCollection(selectedOption.value)
                }
              />
            </SelectWrapper>
          </Column>
        </FirstRow>
        {!popularColors.length ? null : (
          <PopularSection>
            <Title>{t('Popular colours')}</Title>
            <PopularFlexWrap>
              {popularColors.map((color: IColor, index: number) => {
                const isSelected = getIsColorSelected(color, chosenColor);
                return (
                  <PopularColorCard
                    isSelected={isSelected}
                    key={`popular-${index}-${color.name}`}
                    color={color}
                    handleChooseColor={handleChooseColor}
                  />
                );
              })}
            </PopularFlexWrap>
          </PopularSection>
        )}
        <Title>{t('Results')}</Title>
        <ResultsSection>
          <FlexWrap>
            {colors.map((color: IColor, index: number) => {
              const isSelected = getIsColorSelected(color, chosenColor);
              return (
                <ColorCard
                  isSelected={isSelected}
                  key={`${index}-${color.name}`}
                  color={color}
                  handleChooseColor={handleChooseColor}
                />
              );
            })}
          </FlexWrap>
        </ResultsSection>
      </Container>
    </Modal>
  );
}
