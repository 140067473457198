import { COLORS } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isChecked: boolean;
  size: 'small' | 'large';
  disabled: boolean;
}

export const Container = styled.div`
  position: relative;
  width: 24rem;
  height: 10rem;
  border-radius: 5rem;
  cursor: pointer;

  align-self: center;
  ${(props: StyledProps) =>
    props.isChecked &&
    css`
      background-color: ${COLORS.GREEN_DISABLED};
    `};
  ${(props: StyledProps) =>
    !props.isChecked &&
    css`
      background-color: ${COLORS.GRAY_300};
    `};
  ${(props: StyledProps) =>
    props.size === 'large' &&
    css`
      width: 50rem;
      height: 5rem;
    `};
  ${(props: StyledProps) =>
    props.disabled &&
    css`
      background-color: ${COLORS.GRAY_300};
    `};
`;

export const Circle = styled.div`
  position: absolute;
  top: -2rem;
  width: 14rem;
  height: 14rem;
  border-radius: 7rem;

  ${(props: StyledProps) =>
    props.size === 'large' &&
    css`
      width: 25rem;
      height: 25rem;
      border-radius: 13rem;
      top: -10rem;
    `};

  ${(props: StyledProps) => {
    if (props.isChecked) {
      if (props.size === 'large') {
        return css`
          left: 50%;
          transition: 200ms ease-out;
          background-color: ${COLORS.GREEN};
        `;
      } else {
        return css`
          left: 50%;
          transition: 200ms ease-out;
          background-color: ${COLORS.GREEN};
        `;
      }
    } else {
      if (props.size === 'large') {
        return css`
          left: -2rem;
          transition: 200ms ease-out;
          background-color: ${COLORS.GREEN_DISABLED};
        `;
      } else {
        return css`
          left: -2rem;
          transition: 200ms ease-out;
          background-color: ${COLORS.GREEN_DISABLED};
        `;
      }
    }
  }}
  ${(props: StyledProps) =>
    props.disabled &&
    css`
      background-color: ${COLORS.GREEN_DISABLED};
    `};
`;
